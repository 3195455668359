import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import Container from "../components/Container";
import Heading from "../components/Heading";
import Layout from "../components/Layout";
import Link from "../components/Link";
import Paragraph from "../components/Paragraph";
import Spacer from "../components/Spacer";
import { useResize } from "../hooks/useResize";
import { rem } from "../styling/theme";

const ErrorPage404: FC = () => {
  const intl = useIntl();
  const { windowHeight } = useResize();

  return (
    <Layout
      helmetKey="error"
      emptyMenu
      menuIconType="/"
      render={(): React.ReactChild => (
        <Container height={rem(windowHeight)}>
          <Spacer size="huge" />
          <Heading title={intl.formatMessage({ id: "error.title" })} />
          <Paragraph
            paragraph={intl.formatMessage({ id: "error.description" })}
          />
          <Link route="/">{intl.formatMessage({ id: "error.link" })}</Link>
        </Container>
      )}
    />
  );
};

export default ErrorPage404;
